import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableHead, TableContainer, Paper, Typography, Box, Tooltip, Switch, TableSortLabel, Button, TableCell, TableRow, TablePagination, TextField, InputAdornment, IconButton, Autocomplete
} from '@mui/material';
import { Delete as DeleteIcon, ArrowDownward as ArrowDownwardIcon, ArrowUpward as ArrowUpwardIcon, Edit as EditIcon } from '@mui/icons-material';
import axios from 'axios';
import Modal from './Modal';
import { useFormik } from 'formik';
import * as yup from 'yup';

const TokenPool = () => {
  const [pools, setPools] = useState([]);
  const [editRateId, setEditRateId] = useState(null);
  const [editRateValue, setEditRateValue] = useState('');
  const [editMinRedeemId, setEditMinRedeemId] = useState(null);
  const [editMinRedeemValue, setEditMinRedeemValue] = useState('');
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedPool, setSelectedPool] = useState(null);
  const [editingPool, setEditingPool] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('token_id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalPools, setTotalPools] = useState(0);
  const [symbols, setSymbols] = useState([]);
  const [redemptionDisplay, setRedemptionDisplay] = useState('');
  const [channelTokenName, setChannelTokenName] = useState('loyalty tokens');
  const [selectedTokenDecimals, setSelectedTokenDecimals] = useState(2);
  const [selectedTokenSymbol, setSelectedTokenSymbol] = useState('');

  useEffect(() => {
    fetchPools();
    fetchTotalPools();
    fetchSymbols();
    fetchChannelSettings();
  }, [orderBy, order, page, rowsPerPage]);

  const fetchChannelSettings = async () => {
    try {
      const response = await axios.get('/api/channel-settings?setting_type=loyalty');
      const settings = response.data.data;
      const tokenNameSetting = settings.find(setting => setting.setting_key === 'channel_token_name');
      if (tokenNameSetting) {
        setChannelTokenName(tokenNameSetting.setting_value);
      }
    } catch (error) {
      console.error('Error fetching channel settings:', error);
    }
  };

  const fetchPools = async () => {
    try {
      const response = await axios.get('/api/token-pools', {
        params: { sort_by: orderBy, sort_order: order, limit: rowsPerPage, offset: page * rowsPerPage },
      });
      setPools(response.data.data);
    } catch (error) {
      console.error('Error fetching pools:', error);
    }
  };

  const fetchTotalPools = async () => {
    try {
      const response = await axios.get('/api/token-pools/count');
      setTotalPools(response.data.total_count);
    } catch (error) {
      console.error('Error fetching total pools count:', error);
    }
  };

  const fetchSymbols = async () => {
    try {
      const response = await axios.get('/api/tokens');
      const symbolOptions = response.data.map((token) => ({
        value: token.token_id,
        label: `${token.symbol} (${token.contract})`,
        decimals: token.decimals,
      }));
      setSymbols(symbolOptions);
    } catch (error) {
      console.error('Error fetching symbols:', error);
    }
  };

  const handleActiveChange = async (pool) => {
    try {
      const updatedPool = { ...pool, is_active: !pool.is_active };
      await axios.post('/api/token-pools/update', {
        token_id: pool.token_id,
        is_active: updatedPool.is_active,
        rate: pool.rate,
        min_redeem: pool.min_redeem,
      });
      setPools((prevPools) =>
        prevPools.map((p) => (p.token_id === pool.token_id ? updatedPool : p))
      );
    } catch (error) {
      console.error('Error updating active status:', error);
    }
  };

  const handleEditPool = (pool) => {
    setEditingPool(pool);
    setModalType('edit');
    setOpen(true);
    setSelectedTokenDecimals(pool.decimals);
    setSelectedTokenSymbol(pool.symbol);
    updateRedemptionDisplay(pool.rate, pool.min_redeem, pool.decimals, pool.symbol);
  };

  const handleEditSubmit = async (values) => {
    setLoading(true);
    try {
      await axios.post('/api/token-pools/update', {
        token_id: editingPool.token_id,
        rate: values.rate,
        min_redeem: values.min_redeem,
        is_active: editingPool.is_active,
      });
      setMessage('Pool updated successfully!');
      fetchPools();
      setIsSuccess(true);
      handleClose();
    } catch (error) {
      setMessage('Error updating pool. Please try again.');
      setIsError(true);
    } finally {
      setLoading(false);
      setEditingPool(null);
    }
  };

  const handleDeletePool = async (pool) => {
    try {
      const response = await axios.post('/api/token-pool/delete', {
        token_id: pool.token_id,
      });
      if (response.data.success) {
        setMessage(response.data.message);
        setIsSuccess(true);
        fetchPools();
      } else {
        setMessage(response.data.message || 'Error deleting pool.');
        setIsError(true);
      }
    } catch (error) {
      setMessage(error.response?.data?.message || 'Error deleting pool. Please try again later.');
      setIsError(true);
    }
  };

  const handleWithdraw = (pool) => {
    setSelectedPool(pool);
    setModalType('withdraw');
    setOpen(true);
  };

  const handleDeposit = (pool) => {
    setSelectedPool(pool);
    setModalType('deposit');
    setOpen(true);
  };

  const validationSchema = yup.object({
    symbol: yup.object().nullable().required('Symbol is required'),
    rate: yup
      .number('Enter a redemption rate')
      .min(0, 'Redemption rate must be at least 0%')
      .max(100, 'Redemption rate cannot exceed 100%')
      .test('decimal-places', 'Redemption rate can have up to 2 decimal places', (value) =>
        /^\d+(\.\d{1,2})?$/.test(value)
      )
      .required('Redemption rate is required'),
    min_redeem: yup
      .number('Enter a minimum redeem amount')
      .integer('Minimum redeem must be a whole number')
      .min(0, 'Minimum redeem must be at least 0')
      .required('Minimum redeem is required'),
  });

  const formik = useFormik({
    initialValues: {
      symbol: null,
      redemptionRate: '',
      minRedeem: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      setIsSuccess(false);
      setIsError(false);

      // Calculate redemption amount with token precision
      const redemptionAmount = Math.floor(values.minRedeem * (values.redemptionRate / 100) * Math.pow(10, selectedTokenDecimals)) / Math.pow(10, selectedTokenDecimals);

      try {
        const response = await axios.post('/api/token-pool', {
          token_id: values.symbol.value,
          symbol: values.symbol.label.split(' ')[0],
          rate: values.redemptionRate,
          min_redeem: values.minRedeem,
          balance: 0,
          is_active: false,
          redemptionAmount,
        });
        setLoading(false);
        if (response.data.success) {
          setIsSuccess(true);
          setMessage(`Pool created successfully!`);
          fetchPools();
          resetForm();
          setRedemptionDisplay('');
        } else {
          setIsError(true);
          setMessage(response.data.message || 'Error creating pool. Please try again later.');
        }
      } catch (error) {
        setLoading(false);
        setIsError(true);
        setMessage(error.response?.data?.message || 'Error creating pool. Please try again later.');
      }
    },
  });

  const handleSymbolChange = (event, value) => {
    formik.setFieldValue('symbol', value);
    if (value) {
      setSelectedTokenDecimals(value.decimals); // Set the decimals precision based on the selected token
      setSelectedTokenSymbol(value.label.split(' ')[0]);
      updateRedemptionDisplay(formik.values.redemptionRate, formik.values.minRedeem, value.decimals, value.label.split(' ')[0]);
    }
  };

  const handleRedemptionRateChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      formik.setFieldValue('redemptionRate', value);
      updateRedemptionDisplay(value, formik.values.minRedeem, selectedTokenDecimals, selectedTokenSymbol);
    }
  };

  const handleMinRedeemChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      formik.setFieldValue('minRedeem', value);
      updateRedemptionDisplay(formik.values.redemptionRate, value, selectedTokenDecimals, selectedTokenSymbol);
    }
  };

  const updateRedemptionDisplay = (rate, minRedeem, decimals, symbol) => {
    if (rate && minRedeem && decimals !== undefined && symbol) {
      let redemptionAmount = (minRedeem * (rate / 100)).toFixed(decimals);
      setRedemptionDisplay(`Redemption Example: User will receive ${redemptionAmount} ${symbol} per ${minRedeem} ${channelTokenName}`);
    } else {
      setRedemptionDisplay('');
    }
  };
  
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleBlurUpdate = async (id) => {
    const pool = pools.find((p) => p.token_id === id);

    const updatedRate = parseFloat(pool.rate).toFixed(2);
    const updatedMinRedeem = pool.min_redeem !== undefined ? pool.min_redeem : null;
    const updatedIsActive = pool.is_active !== undefined ? pool.is_active : null;

    try {
      await axios.post('/api/token-pools/update', {
        token_id: id,
        rate: updatedRate,
        min_redeem: updatedMinRedeem,
        is_active: updatedIsActive,
      });
    } catch (error) {
      console.error('Error updating token pool:', error);
    } finally {
      setEditRateId(null);
      setEditMinRedeemId(null);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setIsSuccess(false);
    setIsError(false);
    setMessage('');
    setEditingPool(null);
    setRedemptionDisplay('');
  };

  const getModalContent = () => {
    if (modalType === 'create') {
      return (
        <form onSubmit={formik.handleSubmit} noValidate>
          <Box mb={2} />
          <Autocomplete
            options={symbols}
            getOptionLabel={(option) => option.label}
            value={formik.values.symbol}
            onChange={handleSymbolChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Symbol (Contract)"
                variant="outlined"
                error={formik.touched.symbol && Boolean(formik.errors.symbol)}
                helperText={formik.touched.symbol && formik.errors.symbol}
                fullWidth
                margin="normal"
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: 'white', // Sets color of dropdown arrow and clear icon to white
                  },
                }}
              />
            )}
          />
          <TextField
            fullWidth
            id="redemptionRate"
            name="redemptionRate"
            label="Redemption Rate (%)"
            type="text"
            variant="outlined"
            value={formik.values.redemptionRate}
            onChange={handleRedemptionRateChange}
            error={formik.touched.redemptionRate && Boolean(formik.errors.redemptionRate)}
            helperText={formik.touched.redemptionRate && formik.errors.redemptionRate}
            InputLabelProps={{ shrink: true }}
            margin="normal"
            InputProps={{
              inputProps: { inputMode: 'decimal', min: "0" },
            }}
          />
          <TextField
            fullWidth
            id="minRedeem"
            name="minRedeem"
            label="Minimum Redeem"
            type="text"
            variant="outlined"
            value={formik.values.minRedeem}
            onChange={handleMinRedeemChange}
            error={formik.touched.minRedeem && Boolean(formik.errors.minRedeem)}
            helperText={formik.touched.minRedeem && formik.errors.minRedeem}
            InputLabelProps={{ shrink: true }}
            margin="normal"
            InputProps={{
              inputProps: { inputMode: 'numeric' },
            }}
          />
          {redemptionDisplay && (
            <Typography variant="body2" color="#FFFFFF" sx={{ mt: 1 }}>
              {redemptionDisplay}
            </Typography>
          )}
        </form>
      );
    }
    else if (modalType === 'edit' && editingPool) {
      return (
        <Box>
          <TextField
            fullWidth
            label="Redemption Rate (%)"
            type="number"
            value={editingPool.rate}
            onChange={(e) => setEditingPool({ ...editingPool, rate: e.target.value })}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            InputProps={{ inputProps: { step: "0.01", min: "0" } }}
          />
          <TextField
            fullWidth
            label="Minimum Redeem"
            type="number"
            value={editingPool.min_redeem}
            onChange={(e) => setEditingPool({ ...editingPool, min_redeem: e.target.value })}
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      );
    }
    return null;
  };

  return (
    <Box padding={2}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <Typography variant="h5">Token Pools</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setModalType('create');
            setOpen(true);
          }}
          sx={{ color: '#FFFFFF', textTransform: 'none', width: 'auto', whiteSpace: 'nowrap' }}
        >
          ADD POOL
        </Button>
      </Box>
      <Typography variant="body1" color="#A0A0A0">
        Here you can manage your token pools. Toggle the active status to enable or disable a pool or use the icons to deposit or withdraw.
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === 'token_id' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'token_id'}
                  direction={orderBy === 'token_id' ? order : 'asc'}
                  onClick={() => handleRequestSort('token_id')}
                >
                  Pool ID
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'symbol' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'symbol'}
                  direction={orderBy === 'symbol' ? order : 'asc'}
                  onClick={() => handleRequestSort('symbol')}
                >
                  Token Symbol
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'contract' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'contract'}
                  direction={orderBy === 'contract' ? order : 'asc'}
                  onClick={() => handleRequestSort('contract')}
                >
                  Contract
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'balance' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'balance'}
                  direction={orderBy === 'balance' ? order : 'asc'}
                  onClick={() => handleRequestSort('balance')}
                >
                  Balance
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'rate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'rate'}
                  direction={orderBy === 'rate' ? order : 'asc'}
                  onClick={() => handleRequestSort('rate')}
                >
                  Rate
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'min_redeem' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'min_redeem'}
                  direction={orderBy === 'min_redeem' ? order : 'asc'}
                  onClick={() => handleRequestSort('min_redeem')}
                >
                  Min Redeem
                </TableSortLabel>
              </TableCell>
              <TableCell>
                Active
              </TableCell>
              <TableCell>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pools.map((pool) => (
              <TableRow key={pool.token_id}>
                <TableCell>{pool.token_id}</TableCell>
                <TableCell>{pool.symbol}</TableCell>
                <TableCell>{pool.contract}</TableCell>
                <TableCell>{parseFloat(pool.balance).toLocaleString(undefined, { minimumFractionDigits: pool.decimals })}</TableCell>
                <TableCell>{`${parseFloat(pool.rate).toFixed(2)}%`}</TableCell>
                <TableCell>{pool.min_redeem}</TableCell>
                <TableCell>
                  <Switch
                    checked={pool.is_active}
                    onChange={() => handleActiveChange(pool)}
                  />
                </TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                      <IconButton onClick={() => handleEditPool(pool)} sx={{ color: 'white' }}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  <Tooltip title="Deposit">
                    <IconButton onClick={() => handleDeposit(pool)} sx={{ color: 'green' }}>
                      <ArrowDownwardIcon />
                    </IconButton>
                  </Tooltip>
                  {parseFloat(pool.balance) > 0 && (
                    <>
                      <Tooltip title="Withdraw">
                        <IconButton onClick={() => handleWithdraw(pool)} sx={{ color: 'red' }}>
                          <ArrowUpwardIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  {parseFloat(pool.balance) === 0 && (
                    <Tooltip title="Delete Pool">
                      <IconButton color="error" onClick={() => handleDeletePool(pool)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalPools}
        page={page}
        onPageChange={(e, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
        rowsPerPageOptions={[25, 50, 100]}
      />
      <Modal
        open={open}
        onClose={handleClose}
        title={modalType === 'create' ? 'Add New Pool' : modalType === 'edit' ? 'Edit Pool' : 'Details'}
        content={getModalContent()}
        onSubmit={modalType === 'create' ? formik.handleSubmit : handleEditSubmit}
        isProcessing={loading}
        isSuccess={isSuccess}
        isError={isError}
        message={message}
        hideSubmit={modalType === 'details'} // Only hide submit if it's a details view
      />
    </Box>
  );
};

export default TokenPool;
