import React, { useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import { Button, Drawer, List, ListItem, ListItemText, TextField, Box, Typography, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ChatOverlay from '../overlays/ChatOverlay';

const OverlayEditor = () => {
  const [widgets, setWidgets] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedWidgetId, setSelectedWidgetId] = useState(null);
  const [customWidth, setCustomWidth] = useState(1920);
  const [customHeight, setCustomHeight] = useState(1080);
  const [overlayName, setOverlayName] = useState('My Overlay');
  const [isEditingName, setIsEditingName] = useState(false);
  const [zoom, setZoom] = useState(0.8);
  const [isWidgetTypeDialogOpen, setIsWidgetTypeDialogOpen] = useState(false);

  useEffect(() => {
    const updateZoomToFit = () => {
      const drawerWidth = 250;
      const availableWidth = window.innerWidth - drawerWidth - 40;
      const availableHeight = window.innerHeight - 150;
      const widthScale = availableWidth / customWidth;
      const heightScale = availableHeight / customHeight;
      const optimalScale = Math.min(widthScale, heightScale);
      setZoom(Math.min(optimalScale, 0.8));
    };
    updateZoomToFit();
    window.addEventListener('resize', updateZoomToFit);
    return () => window.removeEventListener('resize', updateZoomToFit);
  }, [customWidth, customHeight]);

  const handleNameClick = () => setIsEditingName(true);

  const handleNameChange = (e) => setOverlayName(e.target.value);

  const handleNameBlur = () => setIsEditingName(false);

  const openWidgetTypeDialog = () => setIsWidgetTypeDialogOpen(true);

  const closeWidgetTypeDialog = () => setIsWidgetTypeDialogOpen(false);

  const addWidgetOfType = (widgetType) => {
    const newWidget = {
      id: `widget-${Date.now()}`,
      x: 50,
      y: 50,
      width: 200,
      height: 150,
      zIndex: 1,
      opacity: 1,
      backgroundColor: '#ffffff',
      widgetType,
    };
    setWidgets((prevWidgets) => [...prevWidgets, newWidget]);
    closeWidgetTypeDialog();
  };

  const handleWidgetClick = (widgetId, e) => {
    e.stopPropagation();
    setSelectedWidgetId(widgetId);
  };

  const handleGridClick = () => {
    setSelectedWidgetId(null);
  };

  const updateWidgetProperties = (id, updates) => {
    setWidgets((prevWidgets) =>
      prevWidgets.map((widget) =>
        widget.id === id ? { ...widget, ...updates } : widget
      )
    );
  };

  const renderWidget = (widget) => {
    const isSelected = widget.id === selectedWidgetId;
    const borderStyle = isSelected ? '2px solid blue' : '1px dashed gray';

    return (
      <Rnd
        key={widget.id}
        size={{ width: widget.width, height: widget.height }}
        position={{ x: widget.x, y: widget.y }}
        bounds="parent"
        onDragStop={(e, d) => updateWidgetProperties(widget.id, { x: d.x, y: d.y })}
        onResizeStop={(e, direction, ref, delta, position) => {
          updateWidgetProperties(widget.id, {
            x: position.x,
            y: position.y,
            width: ref.offsetWidth,
            height: ref.offsetHeight,
          });
        }}
        onClick={(e) => handleWidgetClick(widget.id, e)}
        style={{
          border: borderStyle,
          padding: '8px',
          zIndex: widget.zIndex,
          opacity: widget.opacity,
          backgroundColor: widget.backgroundColor,
          position: 'relative',
        }}
        enableResizing={{
          top: true,
          right: true,
          bottom: true,
          left: true,
          topRight: true,
          topLeft: true,
          bottomRight: true,
          bottomLeft: true,
        }}
      >
        <div style={{ textAlign: 'center', color: 'rgba(0, 0, 0, 0.5)', fontSize: '14px', position: 'absolute', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', pointerEvents: 'none' }}>
          {widget.widgetType}
        </div>
        {widget.widgetType === 'ChatOverlay' ? <ChatOverlay /> : <div>Unknown Widget</div>}
      </Rnd>
    );
  };

  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
      {/* Left Drawer for Widget Controls */}
      <Drawer variant="permanent" anchor="left">
        <Box width="250px" padding="1rem">
          {isEditingName ? (
            <TextField
              variant="outlined"
              fullWidth
              size="small"
              value={overlayName}
              onChange={handleNameChange}
              onBlur={handleNameBlur}
              autoFocus
            />
          ) : (
            <Typography variant="h6" onClick={handleNameClick} style={{ cursor: 'pointer' }}>
              {overlayName}
            </Typography>
          )}
          <Divider style={{ margin: '1rem 0' }} />
          {selectedWidgetId === null ? (
            <>
              {/* Grid Settings */}
              <TextField
                label="Width"
                type="number"
                variant="outlined"
                size="small"
                value={customWidth}
                onChange={(e) => setCustomWidth(parseInt(e.target.value, 10))}
                style={{ marginTop: '1rem' }}
                fullWidth
              />
              <TextField
                label="Height"
                type="number"
                variant="outlined"
                size="small"
                value={customHeight}
                onChange={(e) => setCustomHeight(parseInt(e.target.value, 10))}
                style={{ marginTop: '1rem' }}
                fullWidth
              />
            </>
          ) : (
            <>
              {/* Selected Widget Settings */}
              <TextField
                label="X Position"
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={widgets.find((w) => w.id === selectedWidgetId)?.x || 0}
                onChange={(e) => updateWidgetProperties(selectedWidgetId, { x: parseInt(e.target.value, 10) })}
                style={{ marginTop: '1rem' }}
              />
              <TextField
                label="Y Position"
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={widgets.find((w) => w.id === selectedWidgetId)?.y || 0}
                onChange={(e) => updateWidgetProperties(selectedWidgetId, { y: parseInt(e.target.value, 10) })}
                style={{ marginTop: '1rem' }}
              />
              <TextField
                label="Z Index"
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                value={widgets.find((w) => w.id === selectedWidgetId)?.zIndex || 1}
                onChange={(e) => updateWidgetProperties(selectedWidgetId, { zIndex: parseInt(e.target.value, 10) })}
                style={{ marginTop: '1rem' }}
              />
              <TextField
                label="Opacity"
                type="number"
                variant="outlined"
                size="small"
                fullWidth
                inputProps={{ min: 0, max: 1, step: 0.1 }}
                value={widgets.find((w) => w.id === selectedWidgetId)?.opacity || 1}
                onChange={(e) => updateWidgetProperties(selectedWidgetId, { opacity: parseFloat(e.target.value) })}
                style={{ marginTop: '1rem' }}
              />
              <TextField
                label="Background Color"
                type="color"
                variant="outlined"
                size="small"
                fullWidth
                value={widgets.find((w) => w.id === selectedWidgetId)?.backgroundColor || '#ffffff'}
                onChange={(e) => updateWidgetProperties(selectedWidgetId, { backgroundColor: e.target.value })}
                style={{ marginTop: '1rem' }}
              />
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={openWidgetTypeDialog}
            fullWidth
            style={{ marginTop: '1rem' }}
          >
            Add Widget
          </Button>
        </Box>
      </Drawer>

      {/* Main Overlay Editor */}
      <div
        style={{
          flex: 1,
          position: 'relative',
          margin: '20px auto 0',
          marginLeft: 'calc(250px + 1rem)',
          width: customWidth * zoom,
          height: customHeight * zoom,
          border: '2px solid #ddd',
          backgroundSize: '40px 40px',
          backgroundImage: 'radial-gradient(circle, #bbb 1.5px, transparent 1.5px)',
          transform: `scale(${zoom})`,
          transformOrigin: 'top center',
        }}
        onClick={handleGridClick}
      >
        {widgets.map((widget) => renderWidget(widget))}
      </div>

      {/* Floating Bottom Controls */}
      <div style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)', display: 'flex', alignItems: 'center' }}>
        <Tooltip title="Add Widget">
          <IconButton color="primary" onClick={openWidgetTypeDialog}>
            <AddIcon fontSize="large" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Zoom In">
          <IconButton color="primary" onClick={() => setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3))} style={{ marginRight: '0.5rem' }}>
            <ZoomInIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Zoom Out">
          <IconButton color="primary" onClick={() => setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5))}>
            <ZoomOutIcon />
          </IconButton>
        </Tooltip>
      </div>

      {/* Widget Type Selection Dialog */}
      <Dialog open={isWidgetTypeDialogOpen} onClose={closeWidgetTypeDialog}>
        <DialogTitle>Select Widget Type</DialogTitle>
        <DialogContent>
          <List>
            <ListItem button onClick={() => addWidgetOfType('ChatOverlay')}>
              <ListItemText primary="Chat Overlay" />
            </ListItem>
            <ListItem button onClick={() => addWidgetOfType('AlertOverlay')}>
              <ListItemText primary="Alert Overlay" />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeWidgetTypeDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OverlayEditor;
