import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableHead, Paper, Typography, Box, IconButton, Button, Checkbox, TableContainer, TableCell, TableRow, TablePagination, TableSortLabel, CircularProgress
} from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import axios from 'axios';

const Leaderboard = () => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('balance');
  const [totalUsers, setTotalUsers] = useState(0);
  const [loading, setLoading] = useState(false);

  // Fetch total user count
  const fetchTotalUsers = async () => {
    try {
      const response = await axios.get('/api/leaderboard/count', { withCredentials: true });
      setTotalUsers(response.data.total_count);
    } catch (error) {
      console.error('Error fetching total users count:', error);
    }
  };

  // Fetch leaderboard data with sorting and pagination
  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/leaderboard', {
        withCredentials: true,
        params: {
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          sort_by: sortBy === 'lifetimeEarnings' ? 'Lifetime Earnings' : sortBy,
          sort_order: sortOrder,
        },
      });
      setUsers(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTotalUsers();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage, sortBy, sortOrder]);

  // Handle sort request
  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle CSV export
  const exportToCSV = async () => {
    try {
      let allUsers = [];
      const totalPages = Math.ceil(totalUsers / rowsPerPage);
      for (let page = 0; page < totalPages; page++) {
        const response = await axios.get('/api/leaderboard', {
          withCredentials: true,
          params: {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            sort_by: sortBy === 'lifetimeEarnings' ? 'Lifetime Earnings' : sortBy,
            sort_order: sortOrder,
          },
        });
        allUsers = allUsers.concat(response.data);
      }

      const headers = ['Twitch ID', 'Username', 'Balance', 'Spent', 'Lifetime Earnings', 'Messages'];
      const rows = allUsers.map(user => [
        user['Twitch ID'],
        user['Username'],
        user['Balance'],
        user['Spent'],
        user['Lifetime Earnings'],
        user['Messages'],
      ]);

      const csvContent = [
        headers.join(","),
        ...rows.map(row => row.join(",")),
      ].join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `leaderboard_${Date.now()}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting to CSV:', error);
    }
  };

  return (
    <Box sx={{ padding: '16px' }}>
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
      <Box>
        <Typography variant="h5">
          Leaderboard
        </Typography>
        <Typography variant="body1" color="#A0A0A0">
          View your top loyalty token holders, spenders and chatters.
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={exportToCSV}
        disabled={users.length === 0}
      >
        <FileDownloadOutlinedIcon />
        &nbsp;Export
      </Button>
    </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'username'}
                  direction={sortBy === 'username' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('username')}
                >
                  Username
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'balance'}
                  direction={sortBy === 'balance' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('balance')}
                >
                  Balance
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'spent'}
                  direction={sortBy === 'spent' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('spent')}
                >
                  Spent
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'lifetimeEarnings'}
                  direction={sortBy === 'lifetimeEarnings' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('lifetimeEarnings')}
                >
                  Lifetime Earnings
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortBy === 'messages'}
                  direction={sortBy === 'messages' ? sortOrder : 'asc'}
                  onClick={() => handleRequestSort('messages')}
                >
                  Messages
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              users.map((user) => (
                <TableRow key={user['Twitch ID']}>
                  <TableCell>{user['Username']}</TableCell>
                  <TableCell>{Number(user['Balance']).toLocaleString()}</TableCell>
                  <TableCell>{Number(user['Spent']).toLocaleString()}</TableCell>
                  <TableCell>{Number(user['Lifetime Earnings']).toLocaleString()}</TableCell>
                  <TableCell>{Number(user['Messages']).toLocaleString()}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={totalUsers}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Box>
  );
};

export default Leaderboard;