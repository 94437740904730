import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/system';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { AuthModal } from '../components';
import homeVideo from '../streamer.mp4';
import placeholderImage from '../streamerPlaceholder.jpg';

const Container = styled('div')({
  position: 'relative',
  width: '100%',
  height: 'calc(100vh - 118px)',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
});

const BackgroundImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: -1,
  objectFit: 'cover',
  filter: 'brightness(35%)',
});

const VideoBackground = styled('video')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: -1,
  objectFit: 'cover',
  filter: 'brightness(35%)',
  display: 'none', // Initially hidden until it loads
});

const Title = styled('h1')({
  fontSize: '4rem',
  marginBottom: '20px',
  textShadow: '2px 2px #000',
  zIndex: 1,
});

const CTAButton = styled('button')(({ theme }) => ({
  fontSize: '1.5rem',
  padding: '10px 20px',
  color: '#FFFFFF',
  backgroundColor: theme.palette.primary.main,
  border: 'none',
  cursor: 'pointer',
  marginTop: '20px',
  borderRadius: '10px',
  transition: 'transform 0.3s ease',
  zIndex: 1,
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const FeaturesGrid = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: '30px',
  width: '80%',
  gap: '20px',
  zIndex: 1,
});

const FeatureCard = styled('div')({
  backgroundColor: '#444B6E',
  padding: '20px',
  borderRadius: '10px',
  width: '280px',
  textAlign: 'center',
  transition: 'box-shadow 0.3s ease',
  '&:hover': {
    boxShadow: '0px 4px 20px rgba(255, 69, 0, 0.5)',
  },
});

const HomePage = () => {
  const videoRef = useRef(null);
  const { authState } = useAuth();
  const navigate = useNavigate();
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false); // State to track video load

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadeddata', () => {
        setIsVideoLoaded(true); // Show the video when it is loaded
      });
    }
  }, []);

  const handleCTAButtonClick = () => {
    if (authState.isAuthenticated) {
      // Redirect to the dashboard if logged in
      if (authState.role === 'streamer') {
        navigate('/streamer-dashboard');
      } else if (authState.role === 'viewer') {
        navigate('/viewer-dashboard');
      }
    } else {
      // If not logged in, open the login modal
      setAuthModalOpen(true);
    }
  };

  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };

  return (
    <Container>
    {/* Background Image Placeholder */}
      <BackgroundImage
        src={placeholderImage}
        alt="Background placeholder"
        style={{ display: isVideoLoaded ? 'none' : 'block' }} // Hide image when video loads
      />

    {/* Video Background */}
      <VideoBackground
        ref={videoRef}
        autoPlay
        loop
        muted
        style={{ display: isVideoLoaded ? 'block' : 'none' }} // Show video when it's loaded
      >
        <source src={homeVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </VideoBackground>

      <Title>Empower your stream with Scurvy Bot</Title>
      <CTAButton onClick={handleCTAButtonClick}>
        {authState.isAuthenticated ? 'Go To Your Dashboard' : 'Get Started Now!'}
      </CTAButton>

      <FeaturesGrid>
        <FeatureCard>
          <h3>Ultimate stream Loyalty and Rewards</h3>
          <p>Engage with streams to earn loyalty tokens through actions like chatting and subscribing</p>
        </FeatureCard>
        <FeatureCard>
          <h3>Redeem for crypto</h3>
          <p>Claim WAX blockchain tokens from the streamer’s custom pools and redemption rates</p>
        </FeatureCard>
        <FeatureCard>
          <h3>Dig for prizes</h3>
          <p>Use earned loyalty tokens to dig for a chance to find NFTs or other digital prizes in the streamer's custom prize pools</p>
        </FeatureCard>
        <FeatureCard>
          <h3>All in one place</h3>
          <p>Every tool you could need as a streamer - providing enhanced loyalty and rewards through community engagement</p>
        </FeatureCard>
      </FeaturesGrid>

      <AuthModal open={authModalOpen} onClose={handleAuthModalClose} />
    </Container>
  );
};

export default HomePage;
