import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography, Button, CircularProgress, IconButton, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

const Modal = ({ open, onClose, title, content, onSubmit, isProcessing, isSuccess, isError, message, hideSubmit }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Grid container alignItems="center">
          <Grid item xs={11}>
            <Typography variant="h6">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={onClose}
              sx={{ 
                position: 'absolute', 
                right: 8, 
                top: 8, 
                color: 'error.main'
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {isProcessing ? (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="200px">
            <CircularProgress size={50} color="primary" />
            <Typography>Processing...</Typography>
          </Box>
        ) : isSuccess ? (
          <Box display="flex" alignItems="center" justifyContent="center" mb={2} color="success.main">
            <CheckCircleIcon sx={{ mr: 1 }} />
            <Typography>{message}</Typography>
          </Box>
        ) : isError ? (
          <Box display="flex" alignItems="center" justifyContent="center" mb={2} color="error.main">
            <ErrorIcon sx={{ mr: 1 }} />
            <Typography>{message}</Typography>
          </Box>
        ) : (
          content
        )}
      </DialogContent>
      {!hideSubmit && !isProcessing && !isSuccess && !isError && (
        <DialogActions>
          <Button onClick={onSubmit} variant="contained" color="primary" sx={{ mt: 2 }}>
            Submit
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
