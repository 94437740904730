import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Grid, Button } from '@mui/material';
import { Close as CloseIcon, Videocam as VideocamIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import twitchLogo from '../glitch_flat_purple.png';

const AuthModal = ({ open, onClose }) => {
  const baseUrl = window.location.origin; // Get the base URL dynamically

  const handleLogin = (role) => {
    let oauthURL = '';

    if (role === 'streamer') {
      oauthURL = `https://id.twitch.tv/oauth2/authorize?client_id=qm0qb5mdhtd1qefe4oxuhnoe7pdwcx&redirect_uri=${baseUrl}/auth-callback&response_type=code&scope=user:read:email+bits:read+channel:read:hype_train+channel:read:subscriptions+chat:edit+chat:read+channel:manage:extensions+channel:manage:broadcast+channel:manage:polls+clips:edit+moderator:read:chatters+moderator:read:followers`;
    } else {
      oauthURL = `https://id.twitch.tv/oauth2/authorize?client_id=qm0qb5mdhtd1qefe4oxuhnoe7pdwcx&redirect_uri=${baseUrl}/auth-callback&response_type=code&scope=user:read:email`;
    }

    sessionStorage.setItem('authRole', role); // Store the role in session storage
    window.location.href = oauthURL;
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
    <DialogTitle>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={twitchLogo} alt="Twitch Logo" style={{ height: '40px', marginRight: '16px' }} />
        <div style={{ textAlign: 'center' }}>Login with Twitch</div>
      </div>
      <IconButton onClick={onClose} style={{ position: 'absolute', top: '1px', right: '1px', color: '#d60000' }}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
      <DialogContent>
        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <Button variant="fullWidth" onClick={() => handleLogin('streamer')}>
              I'm a streamer
              <VideocamIcon style={{ marginLeft: '10px' }} />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button variant="fullWidth" onClick={() => handleLogin('viewer')}>
              I'm a viewer
              <VisibilityIcon style={{ marginLeft: '10px' }} />
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AuthModal;
