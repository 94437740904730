import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { ClipLoader } from 'react-spinners';

const AuthCallback = () => {
  const { setAuthState } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const hasRun = useRef(false);

  useEffect(() => {
    if (hasRun.current) return; // Prevent duplicate execution
    hasRun.current = true;

    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    const role = sessionStorage.getItem('authRole'); // Retrieve role from session storage

    if (code && role) {
      console.log('Code and role found:', { code, role });
      axios.post('/api/auth/twitch', { code, role }, { withCredentials: true }) // Include credentials with request
        .then(response => {
          console.log('Response from server:', response.data);
          if (response.data.success) {
            const { session_key, twitch_id } = response.data;

            if (session_key && twitch_id) {
              // Set the auth state using the response from the server
              setAuthState({
                session_key,
                role,
                twitch_id,
                isAuthenticated: true
              });

              navigate(role === 'streamer' ? '/streamer-dashboard' : '/viewer-dashboard');  // Redirect to appropriate dashboard
            } else {
              console.error('Login failed, invalid session details');
              setAuthState({
                session_key: null,
                role: null,
                twitch_id: null,
                isAuthenticated: false
              });
            }
          } else {
            console.error('Authentication failed', response.data);
            setAuthState({
              session_key: null,
              role: null,
              twitch_id: null,
              isAuthenticated: false
            });
          }
        })
        .catch(error => {
          console.error('Error during authentication:', error);
          setAuthState({
            session_key: null,
            role: null,
            twitch_id: null,
            isAuthenticated: false
          });
        });
    } else {
      console.error('No code or role found in URL params or session storage');
      setAuthState({
        session_key: null,
        role: null,
        twitch_id: null,
        isAuthenticated: false
      });
    }
  }, [location, setAuthState, navigate]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <ClipLoader size={50} color="#ff7e04" loading={true} />
      <h2>Fetching Twitch information...</h2>
    </div>
  );
};

export default AuthCallback;
