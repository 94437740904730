import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from './AuthContext';
import logo from '../logo.png'; // Scurvy logo
import axios from 'axios';

const WebSocketTest = () => {
  const { authState } = useAuth(); // Access the auth object
  const [ws, setWs] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState('Disconnected');
  const [rawMessages, setRawMessages] = useState([]); // Raw messages
  const [chatMessages, setChatMessages] = useState([]); // Parsed chat messages
  const [inputMessage, setInputMessage] = useState('');
  const [badgeMapping, setBadgeMapping] = useState({}); // Store badge mappings here

  const rawMessagesRef = useRef(null); // To track the raw messages container
  const chatMessagesRef = useRef(null); // To track the chat messages container

  useEffect(() => {
    scrollToBottom(rawMessagesRef);
  }, [rawMessages]);

  useEffect(() => {
    scrollToBottom(chatMessagesRef);
  }, [chatMessages]);

  // Fetch badge mappings from API
  useEffect(() => {
    const fetchBadgeMappings = async () => {
      try {
        const response = await axios.get('/api/badges/chat');
        const badgeData = response.data;

        // Convert array of badge data to a mapping { 'set_id/badge_id': 'image_url' }
        const badgeMap = badgeData.reduce((acc, badge) => {
          acc[`${badge.identifier}`] = badge.image_url;
          return acc;
        }, {});
        
        setBadgeMapping(badgeMap);
      } catch (error) {
        console.error('Error fetching badge mappings:', error);
      }
    };

    fetchBadgeMappings();
  }, []);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      console.error('User is not authenticated');
      return;
    }

    // Create WebSocket connection when component mounts
    const socket = new WebSocket('wss://ws.scurvy.dasker.co.uk:50443');

    socket.onopen = () => {
      setConnectionStatus('Connected');
      addRawMessage('Connected to WebSocket');

      // Send session_key to authenticate the WebSocket connection
      const credentials = { type: 'auth', session_key: authState.session_key };
      socket.send(JSON.stringify(credentials)); // Send the session_key for authentication
    };

    socket.onmessage = (event) => {
      let rawMessage = event.data;
    
      // Clean up unexpected whitespace or control characters before parsing
      rawMessage = rawMessage.replace(/[\u0000-\u001F\u007F-\u009F]/g, ' ').trim();
    
      addRawMessage(`Received raw message: ${rawMessage}`);
    
      // Parse and handle chat messages
      const parsedMessage = JSON.parse(rawMessage);
      if (parsedMessage.type === 'chat') {
        addChatMessage(parsedMessage);
      }
    };

    socket.onerror = (error) => {
      addRawMessage(`WebSocket error: ${error.message}`);
    };

    socket.onclose = (event) => {
      setConnectionStatus('Disconnected');
      addRawMessage(`Connection closed: ${event.reason}`);
    };

    setWs(socket);

    return () => {
      socket.close();
    };
  }, [authState]);

  const scrollToBottom = (ref) => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight - ref.current.clientHeight;
    }
  };

  const addRawMessage = (message) => {
    setRawMessages((prev) => {
      const newMessages = [...prev, message];
      return newMessages.length > 50 ? newMessages.slice(newMessages.length - 50) : newMessages;
    });
  };
  
  const addChatMessage = (message) => {
    setChatMessages((prev) => {
      const newMessages = [...prev, message];
      return newMessages.length > 50 ? newMessages.slice(newMessages.length - 50) : newMessages;
    });
  };
 
  const sendMessage = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const chatMessage = { type: 'chat', text: inputMessage };
      ws.send(JSON.stringify(chatMessage));
      addRawMessage(`Sent: ${inputMessage}`);
      setInputMessage('');
    }
  };

  const parseEmotes = (text, emotes) => {
    if (!emotes) return text;

    const emoteReplacements = emotes.split('/').map(emoteData => {
      const [emoteId, positions] = emoteData.split(':');
      return positions.split(',').map(position => {
        const [start, end] = position.split('-').map(Number);
        return { start, end, emoteUrl: `https://static-cdn.jtvnw.net/emoticons/v2/${emoteId}/default/dark/1.0` };
      });
    }).flat();

    emoteReplacements.sort((a, b) => b.start - a.start);

    emoteReplacements.forEach(({ start, end, emoteUrl }) => {
      const emoteTag = `<img src="${emoteUrl}" alt="emote" style="width: 24px; vertical-align: middle;" />`;
      text = text.slice(0, start) + emoteTag + text.slice(end + 1);
    });

    return text;
  };

  const getBadgeUrl = (badge) => {
    if (badge === 'scurvy') {
      return logo; // Manual override for "scurvy" badge
    }
    
    // Use the exact identifier format "set_id/badge_id" without modifications
    return badgeMapping[badge] || null;
  };
  const renderChatMessage = (message) => {
    const { username, text, badges, color, emotes } = message;

    // Split badge string by "," to handle multiple badges
    const badgeElements = badges
      ? badges.split(',').map((badge, index) => {
          const badgeUrl = getBadgeUrl(badge); // Fetch URL for each badge
          return badgeUrl ? <img key={index} src={badgeUrl} alt={badge} style={{ height: '18px', verticalAlign: 'middle', marginRight: '5px' }} /> : null;
        })
      : null;

    const messageWithEmotes = { __html: parseEmotes(text, emotes) };

    return (
      <div key={message.timestamp} style={{ marginBottom: '10px' }}>
        {badgeElements}
        <strong style={{ color }}>{username}</strong>
        <span>: </span>
        <span dangerouslySetInnerHTML={messageWithEmotes} />
      </div>
    );
  };

  return (
    <div>
      <h3>WebSocket Test</h3>
      <p>Status: {connectionStatus}</p>

      <div style={{ margin: '10px 0' }}>
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Enter message"
        />
        <button onClick={sendMessage} disabled={connectionStatus !== 'Connected'}>
          Send
        </button>
      </div>

      <div style={{ display: 'flex' }}>
        <div
          ref={rawMessagesRef}
          style={{
            flex: 1,
            padding: '10px',
            border: '1px solid #ccc',
            marginRight: '10px',
            height: '400px',
            overflowY: 'auto',
          }}
        >
          <h4>Raw Messages</h4>
          <ul>
            {rawMessages.map((msg, index) => (
              <li key={index} style={{ wordBreak: 'break-all' }}>{msg}</li>
            ))}
          </ul>
        </div>

        <div
          ref={chatMessagesRef}
          style={{
            flex: 1,
            padding: '10px',
            border: '1px solid #ccc',
            height: '400px',
            overflowY: 'auto',
          }}
        >
          <h4>Chat View</h4>
          {chatMessages.map(renderChatMessage)}
        </div>
      </div>
    </div>
  );
};

export default WebSocketTest;
